import React from 'react';
import { useSelector } from 'react-redux';
import { SelectItemType } from '../components/ui/selects/DefaultSelect';
import { AppStore } from '../store/applicationState';
import { API_ROUTE_PATH } from '../utils/api_routes';
import { callApi } from '../utils/callApi';
import {ALL_CYCLES, ALL_GROUPS_TYPES, ALL_GROUPS_TYPES_RAW_DATA} from "../utils/consts";
import {deepClone} from "../utils/deepClone";

export const useCSV = () => {
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [isMasked, setIsMasked] = React.useState(true);
  const [tryMask, setTryMask] = React.useState(false);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const { Workspaces } = useSelector((store: AppStore) => store);
  // const [data, setData] = React.useState(null);
  // const [headers, setHeaders] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const refLinkCvs = React.useRef<any>(null);
  const [selectedPackage, setSelectedPackage] = React.useState<SelectItemType | null>(null);
  const [selectedAssessment, setSelectedAssessment] = React.useState<SelectItemType | null>(null);
  const [selectedCycles, setSelectedCycles] = React.useState<string[]>([]);
  const [selectCycles, setSelectCycles] = React.useState<SelectItemType[]>([]);

  const [activeCyclesLinks, setActiveCyclesLinks] = React.useState<SelectItemType[]>(
      []
  );
  const [selectOriginSource, setSelectOriginSource] = React.useState<SelectItemType[]>([ALL_GROUPS_TYPES]);
  const [selectedOriginSource, setSelectedOriginSource] = React.useState<SelectItemType[] | null>([ALL_GROUPS_TYPES]);


  const ref = React.useRef(null);

  const cycleSelectHandler = (data: SelectItemType) => {
    if (
        data.value === ALL_CYCLES.value &&
        activeCyclesLinks[0].value === ALL_CYCLES.value
    ) {
      return null;
    }
    const newData = deepClone(activeCyclesLinks) as SelectItemType[];
    const links: string[] = [];

    if (data.value === ALL_CYCLES.value) {
      setActiveCyclesLinks([ALL_CYCLES]);
      selectCycles.forEach((itm) => {
        if (itm.value !== ALL_CYCLES.value) {
          links.push(itm.value);
        }})
      setSelectedCycles(links)
      return
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_CYCLES.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_CYCLES.value) {
        links.push(itm.value);
      }
    });

    if (!newData[0]) {
      newData.push(ALL_CYCLES);
    }

    setActiveCyclesLinks(newData);
    setSelectedCycles(links)
    return
  };

  const popUpYesHandler = () => {
    setIsMasked(false);
    setTryMask(false);
  };
  const popUpNoHandler = () => {
    setIsMasked(true);
    setTryMask(false);
  };
  const maskDataHandler = () => {
    if (isMasked) {
      setTryMask(true);
    } else {
      setTryMask(false);
      setIsMasked(true);
    }
  };


  const genCSV = async () => {
    if (!Workspaces.current) return null;
    if (!selectedPackage) return null;
    if (!selectedAssessment) return null;
    if(!selectedCycles) return null;
    setIsLoading(true);
    try {
      const resp = await callApi({
        method: 'post',
        path: API_ROUTE_PATH.reports.genCsvByParams(
          Workspaces.current.id,
          selectedAssessment.id,
          selectedPackage.id as string,
        ),
        data: {
          links: selectedCycles,
          origin_sources: selectedOriginSource[0].id === ALL_GROUPS_TYPES_RAW_DATA.id ? [] : selectedOriginSource.map(originSource => originSource.id as string),
          is_all_origin_sources : selectedOriginSource[0].id === ALL_GROUPS_TYPES_RAW_DATA.id,
          is_private_data_hidden: isMasked,
        },
        responseType: 'blob',
      });
      // setHeaders(resp.headers);
      // setData(resp.data);
      // if (refLinkCvs && refLinkCvs.current) {
      //   refLinkCvs.current.link.click();
      // }
      const outputFilename = `Raw_data_${selectedAssessment?.title}${
           (isMasked && `_masked`) || (isMasked === false && '')
         }.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(resp);
      const linkDoc = document.createElement('a');
      linkDoc.href = url;
      linkDoc.setAttribute('download', outputFilename);
      document.body.appendChild(linkDoc);
      linkDoc.click();
    } catch (e) {
      console.log(e);
      alert(JSON.stringify(e));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    selectedOriginSource,
    setSelectedOriginSource,
    selectOriginSource,
    setSelectOriginSource,
    refLinkCvs,
    ref,
    isLoading,
    genCSV,
    selectedAssessment,
    selectedPackage,
    selectedCycles,
    setSelectedAssessment,
    setSelectCycles,
    selectCycles,
    setSelectedPackage,
    setSelectedCycles,
    cycleSelectHandler,
    activeCyclesLinks,
    setActiveCyclesLinks,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setTryMask,
    setIsMasked,
    tryMask,
    maskDataHandler,
    popUpYesHandler,
    popUpNoHandler,
    isMasked,
  };
};
