export const ParticipantSources: { [key: string]: string } = {
  // "": "Default",
  "participants_ethnicity_column": "Ethnicity",
  "participants_gender_column": "Gender",
  "participants_job_column": "Job",
  "participants_department_column": "Department",
  "participants_segment_column": "Department segment",
  "participants_home_base_column": "Division",
  "participants_work_location_name_column": "Work location name",
  "participants_work_location_country_column": "Work location country",
  "participants_work_location_state_column": "Work location state",
  "participants_work_location_city_column": "Work location city",
  "participants_pay_group_column": "Pay group",
  "participants_birth_date_column": "Birth date",
};

export interface TParticipant {
  id: string;
  first_name: string;
  last_name: string;
  add_source: 'custom' | 'automatic';
}

export interface TGroupSelect {
  id: string;
  name: string;
  origin_source: string;
}

export interface TGroup {
  id: string;
  name: string;
  description: string;
  origin: 'custom' | 'automatic' | 'default';
  origin_source: string;
  check_for_deletion: boolean;
  created_at: Date | string;
  updated_at: Date | string;
  workspace_id: string;
  participants: TParticipant[];
}

export interface TGroupState {
  readonly loading: boolean;
  readonly data: TGroup[] | null;
  readonly errors?: string | undefined;
}

enum ActionTypes {
  GET_ALL_BY_ORIGIN_SOURCE_R = '@@group/GET_ALL_BY_ORIGIN_SOURCE_R',
  GET_ALL_BY_ORIGIN_SOURCE_S = '@@group/GET_ALL_BY_ORIGIN_SOURCE_S',
  GET_ALL_BY_ORIGIN_SOURCE_E = '@@group/GET_ALL_BY_ORIGIN_SOURCE_E',

  GET_ALL_R = '@@group/GET_ALL_R',
  GET_ALL_S = '@@group/GET_ALL_S',
  GET_ALL_E = '@@group/GET_ALL_E',

  CREATE_R = '@@group/CREATE_R',
  CREATE_S = '@@group/CREATE_S',
  CREATE_E = '@@group/CREATE_E',

  DELETE_R = '@@group/DELETE_R',
  DELETE_S = '@@group/DELETE_S',
  DELETE_E = '@@group/DELETE_E',

  EDIT_R = '@@group/EDIT_R',
  EDIT_S = '@@group/EDIT_S',
  EDIT_E = '@@group/EDIT_E',

  SET_LOADING = '@@group/SET_LOADING',

  CLEAN_UP = '@@group/CLEAN_UP',
}

export default ActionTypes;
