import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreYouShurePopUp } from '../components';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import {AssessmentIcon} from '../components/svgs';
import { Alert, CheckBox, DefaultSelect, Loader } from '../components/ui';
import { ButtonDefS } from '../components/ui/buttons/styles';
import { SelectItemType } from '../components/ui/selects/DefaultSelect';
import { MainWrapperS } from '../globalStyle';
import { useCSV } from '../hooks/useCsv';
import { AppStore } from '../store/applicationState';
import { API_ROUTE_PATH } from '../utils/api_routes';
import { callApi } from '../utils/callApi';
import { TEXT } from '../utils/textConst';
import {ALL_CYCLES, ALL_GROUPS_TYPES_RAW_DATA} from "../utils/consts";
import {COLORS} from "../utils/colors";
import {XlsxIcon} from "../components/svgs/XlsxIcon";
import {deepClone} from "../utils/deepClone";
import {ParticipantSources} from "../store/groups/types";
interface RowDataProps {}

export const RowData: React.FC<RowDataProps> = () => {
  const { Cats, Workspaces } = useSelector((store: AppStore) => store);
  const [errorText, setErrorText] = React.useState('');
  const [selectPackage, setSelectPackage] = React.useState<SelectItemType[]>([]);
  const [selectAssessment, setSelectAssessment] = React.useState<SelectItemType[]>([]);
  // const [selectCycles, setSelectCycles] = React.useState<SelectItemType[]>([]);
  const [isLoadingOriginSource, setIsLoadingOriginSource] = React.useState(true);
  const [isLoadingPackage, setIsLoadingPackage] = React.useState(true);
  const [isLoadingAss, setIsLoadingAss] = React.useState(true);
  const [isLoadingCycle, setIsLoadingCycle] = React.useState(true);
  const [step, setStep] = React.useState(1);
  const dispatch = useDispatch();

  const {
    selectedOriginSource,
    setSelectedOriginSource,
    selectOriginSource,
    setSelectOriginSource,
    selectedPackage,
    selectedAssessment,
      setSelectedCycles,
    setSelectedPackage,
    setSelectedAssessment,
    cycleSelectHandler,
    setSelectCycles,
    selectCycles,
    activeCyclesLinks,
    setActiveCyclesLinks,
    genCSV,
    isLoading,
    tryMask,
    maskDataHandler,
    isMasked,
    popUpYesHandler,
    popUpNoHandler,
  } = useCSV();

  const PREF = ' (archived)';

  React.useEffect(() => {
    const callFn = async () => {
      if (!Workspaces.current!.id)
        return setErrorText('No Workspaces id, ' + TEXT.tryLater);
      try {
        setSelectPackage(null);
        setSelectedPackage(null)
        setSelectAssessment(null);
        setSelectedAssessment(null);
        setSelectCycles([]);
        setSelectedCycles([]);
        setIsLoadingPackage(true);
        setStep(1)
        const resp = (await callApi({
          path: `${API_ROUTE_PATH.packages.getAnsweredByWorkspace(
            Workspaces.current!.id,
              'true',
          )}`,
          method: 'get',
        })) as {
          id: string;
          name: string;
        }[];
        if (!Array.isArray(resp))
          return setErrorText('Wrang format API data, ' + TEXT.tryLater);
        const newSelectData: SelectItemType[] = [];
        resp.forEach((itm) => {
          let name = itm.name;
          if (
            resp.find(
              (pkg) => pkg.name === itm.name && pkg.id !== itm.id
            )
          )
            name = `${itm.name}`;
          newSelectData.push({
            title: name,
            value: itm.id,
            id: itm.id,
          });
        });

        setSelectPackage(newSelectData);
      } catch (error) {
        setErrorText(`${error}`);
      } finally {
        setIsLoadingPackage(false);
      }
    };

    if (Workspaces.current) {
      callFn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Workspaces.current, dispatch]);

  const callOriginSources = async (item: SelectItemType) => {
    if (!Workspaces.current!.id)
      return setErrorText('No Workspaces id, ' + TEXT.tryLater);
    if (!item?.id)
      return setErrorText('No select Origin Source, ' + TEXT.tryLater);

    try {
      setIsLoadingOriginSource(true);
      setSelectedOriginSource([ALL_GROUPS_TYPES_RAW_DATA])
      const resp = (await callApi({
        path: `${API_ROUTE_PATH.groups.gOriginSources(
            Workspaces.current!.id
        )}`,
        method: 'get',
      })) as {
        origin_sources: string[];
      };

      if (!Array.isArray(resp.origin_sources))
        return setErrorText('Wrang format API data, ' + TEXT.tryLater);
      const newSelectData: SelectItemType[] = [ALL_GROUPS_TYPES_RAW_DATA];

      resp.origin_sources.forEach((itm) => {
        newSelectData.push({
          title: ParticipantSources[itm],
          value: itm,
          id: itm,
        });
      });

      setSelectOriginSource(newSelectData);
    } catch (error) {
      setErrorText(`${error}`);
    } finally {
      setIsLoadingOriginSource(false);
    }
  };

  const originSourceSelectHandler = (data: SelectItemType) => {
    if (
        data.value === ALL_GROUPS_TYPES_RAW_DATA.value &&
        selectedOriginSource[0].value === ALL_GROUPS_TYPES_RAW_DATA.value
    ) {
      return null;
    }
    const newData = deepClone(selectedOriginSource) as SelectItemType[];
    const originSources: SelectItemType[] = [];

    if (data.value === ALL_GROUPS_TYPES_RAW_DATA.value) {
      setSelectedOriginSource([ALL_GROUPS_TYPES_RAW_DATA]);
      setSelectedOriginSource([ALL_GROUPS_TYPES_RAW_DATA])
      return
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_GROUPS_TYPES_RAW_DATA.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_GROUPS_TYPES_RAW_DATA.value) {
        originSources.push(itm);
      }
    });

    if (!newData[0]) {
      newData.push(ALL_GROUPS_TYPES_RAW_DATA);
    }

    setSelectedOriginSource(newData);
    return
  };

  const callAss = async (itm:  SelectItemType) => {
    if (!Workspaces.current!.id)
      return setErrorText('No Workspaces id, ' + TEXT.tryLater);
    if (!itm?.id)
      return setErrorText('No Package id, ' + TEXT.tryLater);
    try {
      setSelectAssessment(null);
      setSelectedAssessment(null);
      setSelectCycles([]);
      setIsLoadingAss(true);
      setSelectedPackage(itm)
      setStep(2)
      const resp = (await callApi({
        path: `${API_ROUTE_PATH.assessments.getAnsweredByWorkspace(
            Workspaces.current!.id,
            itm.id as string,
        )}`,
        method: 'get',
      })) as {
        id: string;
        name: string;
        is_archived: boolean;
        version: string;
      }[];
      if (!Array.isArray(resp))
        return setErrorText('Wrang format API data, ' + TEXT.tryLater);
      const newSelectData: SelectItemType[] = [];
      resp.forEach((itm) => {
        let name = itm.name;
        if (
            resp.find(
                (ass) => ass.name === itm.name && ass.id !== itm.id && ass.version
            )
        )
          name = `${itm.name} ${itm.version}`;
        newSelectData.push({
          title:  !itm.is_archived ? name : name + PREF,
          value: itm.id,
          id: itm.id,
        });
      });

      setSelectAssessment(newSelectData);
    } catch (error) {
      setErrorText(`${error}`);
    } finally {
      setIsLoadingAss(false);
    }
  };

  const callCycles = async (item:  SelectItemType) => {
    if (!Workspaces.current!.id)
      return setErrorText('No Workspaces id, ' + TEXT.tryLater);
    if (!item)
      return setErrorText('No selected items, ' + TEXT.tryLater);
    try {
      setIsLoadingCycle(true);
      setSelectedAssessment(item)
      setStep(3)
      const resp = (await callApi({
        path: `${API_ROUTE_PATH.packages.getAnsweredCyclesByWorkspace(
            Workspaces.current!.id,
            selectedPackage.id as string,
            item.id as string,
            true
        )}`,
        method: 'get',
      })) as {
        from: string;
        to: string;
        is_forever_package: boolean;
        link: string;
      }[];
      if (!Array.isArray(resp))
        return setErrorText('Wrang format API data, ' + TEXT.tryLater);

      const newCycles: typeof selectCycles = [ALL_CYCLES];
      var links: string[] = [];
      resp.forEach((itm) => {
        const dateFrom = new Date(itm.from);
        const dateTo = new Date(itm.to);
        const dateNow = new Date();
        const dateNull = new Date('0001-01-01T00:00:00Z');

        const dateFromString = `${dateFrom.getMonth()+1}/${dateFrom.getDate()}/${dateFrom.getFullYear()}`
        const dateToString = `${dateTo.getMonth()+1}/${dateTo.getDate()}/${dateTo.getFullYear()}`

        let dateString = dateFromString

        if ((dateNow < dateTo) || (dateTo.getTime() === dateNull.getTime() && itm.is_forever_package)) {
          dateString += ' to current'
        }
        if (dateNow > dateTo && (dateTo.getTime() !== dateNull.getTime() && !itm.is_forever_package)) {
          dateString += ' to ' + dateToString;
        }

        newCycles.push({
          title: dateString,
          value: itm.link,
          id: itm.link,
        });
        links.push(itm.link)
      });

      setSelectedCycles(links)
      setSelectCycles(newCycles);
      setActiveCyclesLinks([ALL_CYCLES]);
    } catch (error) {
      setErrorText(`${error}`);
    } finally {
      setIsLoadingCycle(false);
    }
  };
  const render = () => {
    if (errorText) {
      return (
        <div style={{ marginTop: '20px' }}>
          <Alert text={errorText} />
        </div>
      );
    }
    // if (isDataLoading) {
    //   return <Loader isGreen />;
    // }

    if (!Cats.data && !Cats.loading) {
      return (
        <div style={{ marginTop: '20px' }}>
          <Alert text="No assessments" type="warning" />
        </div>
      );
    }
    return (
      <div style={{ position: 'relative' }}>
        <SelectWrapperS>
          {step >= 1&&
            <DefaultSelect
                data={selectPackage}
                icon={AssessmentIcon}
                selected={selectedPackage}
                label="Choose Package"
                onChange={(e) => {
                  callAss(e)
                }}
                placeHolder="Choose Package"
                isLoading={isLoadingPackage}
            />
          }
          {step >= 2&&
          <DefaultSelect
              data={selectAssessment}
              icon={AssessmentIcon}
              selected={selectedAssessment}
              label="Choose Assessment"
              onChange={(e) => {
                callCycles(e)
                callOriginSources(e)
              }}
              placeHolder="Choose Assessment"
              isLoading={isLoadingAss}
          />
          }
          {step === 3&&
              <>
                <DefaultSelect
                    data={selectCycles}
                    label="Choose Cycle"
                    placeHolder="Choose Cycle"
                    icon={AssessmentIcon}
                    selected={ selectCycles[0] }
                    onChange={cycleSelectHandler}
                    activeSelects={activeCyclesLinks.length !== 0 ? activeCyclesLinks : [] }
                    isMultiple
                    isLoading={isLoadingCycle}
                />
                <DefaultSelect
                    data={selectOriginSource}
                    label="Choose Group Types"
                    placeHolder="Choose Group Types"
                    icon={AssessmentIcon}
                    selected={ selectOriginSource[0]}
                    onChange={originSourceSelectHandler}
                    activeSelects={selectedOriginSource?.length !== 0 ? selectedOriginSource : [] }
                    isMultiple
                    isLoading={isLoadingOriginSource}
                />
              </>
          }
        </SelectWrapperS>
        {/*<div style={{ margin: '20px 0', maxWidth: '500px' }}>*/}
        {/*  <p style={{ margin: '0 0 5px 0' }}>Choose Time Period</p>*/}
        {/*  <WrapperDateS>*/}
        {/*    <span>From: </span>*/}
        {/*    <DatePicker*/}
        {/*      placeholderText="All time"*/}
        {/*      selected={startDate}*/}
        {/*      onChange={(date) => setStartDate(date as Date)}*/}
        {/*      selectsStart*/}
        {/*      startDate={startDate}*/}
        {/*      endDate={endDate}*/}
        {/*    />*/}
        {/*    <span>To: </span>*/}
        {/*    <DatePicker*/}
        {/*      placeholderText="All time"*/}
        {/*      selected={endDate}*/}
        {/*      onChange={(date) => setEndDate(date as Date)}*/}
        {/*      selectsEnd*/}
        {/*      startDate={startDate}*/}
        {/*      endDate={endDate}*/}
        {/*      minDate={startDate}*/}
        {/*    />*/}
        {/*  </WrapperDateS>*/}
        {/*</div>*/}

        <CheckWrapperS>
          <CheckBox onChange={maskDataHandler} isCheck={isMasked} />
          <span style={{ marginLeft: 7 }}>Mask participant identities</span>
        </CheckWrapperS>
        <ButtonDefS
          onClick={!isLoading ? () => genCSV() : () => null}
          style={{ maxWidth: '97px' }}
          disabled={tryMask || activeCyclesLinks.length === 0}
        >
          {isLoading ? <Loader size={0.5} /> : 'Export'}
        </ButtonDefS>

        {/*<CSVLink*/}
        {/*  data={data || ''}*/}
        {/*  headers={headers || []}*/}
        {/*  ref={refLinkCvs}*/}
        {/*  style={{ display: 'none' }}*/}
        {/*  filename={*/}
        {/*    selectedAssessment &&*/}
        {/*    `${selectedAssessment.title}${*/}
        {/*      (isMasked && `_masked`) || (isMasked === false && '')*/}
        {/*    }.csv`*/}
        {/*  }*/}
        {/*  // enclosingCharacter={`'`}*/}
        {/*>*/}
        {/*  Download me*/}
        {/*</CSVLink>*/}
        {/*{isDataLoading ? <Loader isGreen /> : null}*/}
      </div>
    );
  };

  return (
    <>
      <MainWrapperS>
        <PageTitleWrapperS>
          <h1>Raw Data</h1>
        </PageTitleWrapperS>
        <WrapperTitleIconS>
          <XlsxIcon />
          <h3>Export XLSX File</h3>
          <InfoIS>
            i
            <div>
              <div>
                To load the file with raw data, please select the package (from the list of packages with answers),
                then one of the package assessments  (from the assessments list with answers),
                and then select the cycle/cycles from the list of cycles with answers on the selected assessment
                (in the dropdown you can see the start dates of the cycles for choosing).
                Additionally, select the group types, that should be presented in the report.
              </div>
            </div>
          </InfoIS>
        </WrapperTitleIconS>
        {render()}
      </MainWrapperS>
      {tryMask ? (
        <AreYouShurePopUp
          agreeText={'Yes, I am sure'}
          disagreeText={'No, leave it checked'}
          yesClick={popUpYesHandler}
          noClick={popUpNoHandler}
        />
      ) : null}
    </>
  );
};

const InfoIS = styled.div`
  font-size: 12px;
  position: relative;
  left: 10px;
  top: 8px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: ${COLORS.accent};
  color: white;
  text-align: center;
  margin-left: 2px;
  border-radius: 10px;
  z-index: 3;

  & > div {
    background-color: white;
    font-weight: normal;
    font-size: 14px;
    display: none;
    position: absolute;
    top: 200%;
    width: 250px;
    left: -75px;
    border-radius: 10px;
    box-shadow: 0 11px 33px -9px rgba(42, 47, 42, 0.23);
    border: solid 1px rgba(162, 165, 162, 0.15);
    color: ${COLORS.default};
    text-align: left;
    padding: 15px;
  }

  &:hover {
    & > div {
      display: block;
    }
  }
`;

const CheckWrapperS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
`;
const WrapperTitleIconS = styled.div`
  display: flex;
  align-items: center;
  h3 {
    font-size: 1.6rem;
    margin: 0 0 0 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #171717;
  }
`;

const SelectWrapperS = styled.div`
  width: 100%;
  margin: 24px 0;
  max-width: 328px;

  & > * {
    margin-bottom: 15px;
  }

  .svgIconSelect {
    opacity: 0.3;
  }
`;

