import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import { Alert, DefaultSelect, Loader } from '../../components/ui';
import {
    ButtonBorderedS,
    ButtonDefS,
} from '../../components/ui/buttons/styles';
import { SelectItemType } from '../../components/ui/selects/DefaultSelect';
import { TWorkspace } from '../../store/workspaces/types';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { ROUTE_PATH } from '../../utils/routes';
import { TEXT } from '../../utils/textConst';
import {AssessmentIcon} from "../../components/svgs";
import {useGroupReport} from "../../pages/admin/hooks/useGroupReport";
import {ALL_GROUPS_TYPES} from "../../utils/consts";
import {ParticipantSources} from "../../store/groups/types";
import {deepClone} from "../../utils/deepClone";

interface GroupsReportProps {
    data: [];
}

export const GroupsReport: React.FC<GroupsReportProps> = () => {
    // const [errors, setErrorText] = React.useState('');
    // // eslint-disable-next-line
    // const [permissionError, setPermissionError] = React.useState(true);
    // const [step, setStep] = React.useState(1);
    // const [teams, setTeams] = React.useState<SelectItemType[]>([]);
    // const [currentTeam, setCurrentTeam] = React.useState<SelectItemType | null>(
    //     null
    // );
    // const [packages, setPackages] = React.useState<SelectItemType[]>([]);
    // const [currentPackage, setCurrentPackage] = React.useState<SelectItemType | null>(null);
    // const [cycles, setCycles] = React.useState<SelectItemType[]>([]);
    // const [currentCycle, setCurrentCycle] = React.useState<SelectItemType | null>(
    //     null
    // );
    // const [worksapces, setWorkspaces] = React.useState<SelectItemType[]>([]);
    // const [currentWorkspace, setCurrentWorkspace] =
    //     React.useState<SelectItemType | null>(null);
    // const [isLoading, setIsLoading] = React.useState(false);
    // // eslint-disable-next-line
    // const [isLoadingTeam, setIsLoadingTeam] = React.useState(true);
    // // eslint-disable-next-line
    // const [isLoadingWorkspace, setIsLoadingWorkspace] = React.useState(true);
    // // eslint-disable-next-line
    // const [isLoadingPackage, setIsLoadingPackage] = React.useState(true);
    // const [isLoadingCycle, setIsLoadingCycle] = React.useState(true);

    // const [selectTeams, setSelectTeams] = React.useState<SelectItemType[]>([]);
    // const [selectedTeam, setSelectedTeam] = React.useState<SelectItemType | null>(
    //     null
    // );
    //
    // const [selectWorksapces, setSelectWorksapces] = React.useState<SelectItemType[]>([]);
    // const [selectedWorkspace, setSelectedWorkspace] =
    //     React.useState<SelectItemType | null>(null);

    const [isLoadingTeam, setIsLoadingTeam] = React.useState(true);
    
    const [isLoadingWorkspace, setIsLoadingWorkspace] = React.useState(true);

    const [errorText, setErrorText] = React.useState('');
    const [selectPackage, setSelectPackage] = React.useState<SelectItemType[]>([]);
    const [selectCycle, setSelectCycle] = React.useState<SelectItemType[]>([]);
    const [isLoadingOriginSource, setIsLoadingOriginSource] = React.useState(true);
    const [isLoadingPackage, setIsLoadingPackage] = React.useState(true);
    const [isLoadingCycle, setIsLoadingCycle] = React.useState(true);
    const [step, setStep] = React.useState(1);
    const [stepNotPage, setStepNotPage] = React.useState(1);

    const history = useHistory();

    const {
        selectTeams,
        setSelectTeams,
        selectedTeam,
        setSelectedTeam,
        selectWorksapces,
        setSelectWorksapces,
        selectedWorkspace,
        setSelectedWorkspace,
        setSelectOriginSource,
        selectOriginSource,
        selectedOriginSource,
        selectedPackage,
        selectedCycle,
        setSelectedOriginSource,
        setSelectedCycle,
        setSelectedPackage,
        genXlsx,
        isLoading
    } = useGroupReport();

    const getTeams = async () => {
        interface ITeamsData {
            id: string;
            name: string;
        }

        try {
            setIsLoadingTeam(true)
            setErrorText('');
            const data = (await callApi({
                path: API_ROUTE_PATH.admins.teamsPath,
                method: 'get',
            })) as ITeamsData[];

            if (
                !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
            ) {
                console.log(data, 'error data');
                return setErrorText(TEXT.someError);
            }

            const newTeams: typeof selectTeams = [];

            data.forEach((itm) => {
                newTeams.push({
                    title: itm.name,
                    value: itm.id,
                    id: itm.id,
                });
            });
            return setSelectTeams(newTeams);
        } catch (e) {
            setErrorText(`${e}`);
            window.location.href = '/';
        } finally {
            setIsLoadingTeam(false);
        }
    };

    const getWorkspaces = async (item: SelectItemType) => {
        if (!item?.id)
            return setErrorText('No Team id, ' + TEXT.tryLater);
        try {
            setErrorText('');
            setSelectedTeam(item)
            setIsLoadingWorkspace(true)
            setStepNotPage(2)
            const data = (await callApi({
                path: API_ROUTE_PATH.admins.workspacesPath(item.id as string),
                method: 'get',
            })) as TWorkspace[];

            if (
                !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
            ) {
                console.log(data, 'error data');
                return setErrorText(TEXT.someError);
            }

            const newWorkspaces: typeof selectWorksapces = [];

            data.forEach((itm) => {
                newWorkspaces.push({
                    title: itm.name,
                    value: itm.id,
                    id: itm.id,
                });
            });
            return setSelectWorksapces(newWorkspaces);
        } catch (e) {
            setErrorText(`${e}`);
            window.location.href = '/';
        } finally {
            setIsLoadingWorkspace(false);
        }
    };

    const getPackages = async (item: SelectItemType) => {
        if (!item?.id)
            return setErrorText('No Workspace id, ' + TEXT.tryLater);
        try {
            setErrorText('');
            setSelectedWorkspace(item)
            setIsLoadingPackage(true)
            setStepNotPage(3)
            const data = (await callApi({
                path: `${API_ROUTE_PATH.admins.getAnsweredByWorkspace(
                    item.id as string,
                    false
                )}`,
                method: 'get',
            })) as {
                id: string;
                name: string;
            }[];

            if (!Array.isArray(data))
                return setErrorText('Wrang format API data, ' + TEXT.tryLater);
            const newSelectData: SelectItemType[] = [];

            data.forEach((itm) => {
                let name = itm.name;
                if (
                    data.find(
                        (pkg) => pkg.name === itm.name && pkg.id !== itm.id
                    )
                )
                    name = `${itm.name}`;
                newSelectData.push({
                    title: name,
                    value: itm.id,
                    id: itm.id,
                });
            });
            return setSelectPackage(newSelectData);
        } catch (e) {
            setErrorText(`${e}`);
            window.location.href = '/';
        } finally {
            setIsLoadingPackage(false);
        }
    };

    const getCycles = async (item: SelectItemType) => {
        if (!item?.id)
            return setErrorText('No Package id, ' + TEXT.tryLater);
        try {
            setIsLoadingCycle(true);
            setSelectedPackage(item)
            setSelectedCycle(null)
            setStepNotPage(4)
            const resp = (await callApi({
                path: `${API_ROUTE_PATH.admins.getAnsweredCyclesByWorkspace(
                    selectedWorkspace.id as string,
                    item.id as string,
                    '00000000-0000-0000-0000-000000000000',
                    false
                )}`,
                method: 'get',
            })) as {
                from: string;
                to: string;
                is_forever_package: boolean;
                link: string;
            }[];

            if (!Array.isArray(resp))
                return setErrorText('Wrang format API data, ' + TEXT.tryLater);

            const newCycles: SelectItemType[] = [];
            var links: string[] = [];
            resp.forEach((itm) => {
                const dateFrom = new Date(itm.from);
                const dateTo = new Date(itm.to);
                const dateNow = new Date();
                const dateNull = new Date('0001-01-01T00:00:00Z');

                const dateFromString = `${dateFrom.getMonth()+1}/${dateFrom.getDate()}/${dateFrom.getFullYear()}`
                const dateToString = `${dateTo.getMonth()+1}/${dateTo.getDate()}/${dateTo.getFullYear()}`

                let dateString = dateFromString

                if ((dateNow < dateTo) || (dateTo.getTime() === dateNull.getTime() && itm.is_forever_package)) {
                    dateString += ' to current'
                }
                if (dateNow > dateTo && (dateTo.getTime() !== dateNull.getTime() && !itm.is_forever_package)) {
                    dateString += ' to ' + dateToString;
                }

                newCycles.push({
                    title: dateString,
                    value: itm.link,
                    id: itm.link,
                });
                links.push(itm.link)
            });

            setSelectCycle(newCycles);
        } catch (error) {
            setErrorText(`${error}`);
        } finally {
            setIsLoadingCycle(false);
        }
    };

    const callOriginSources = async (item: SelectItemType) => {
        if (!item?.id)
            return setErrorText('No Period, ' + TEXT.tryLater);

        try {
            setIsLoadingOriginSource(true);
            setSelectedCycle(item)
            setSelectedOriginSource([ALL_GROUPS_TYPES])
            setStepNotPage(5)
            const resp = (await callApi({
                path: `${API_ROUTE_PATH.groups.gOriginSourceAnsweredByWorkspaceID(
                    selectedWorkspace.id as string
                )}`,
                method: 'post',
                data: {
                    package_id: selectedPackage.id,
                    link: item.id,
                    is_all: false
                }
            })) as {
                origin_sources: string[];
            };

            if (!Array.isArray(resp.origin_sources))
                return setErrorText('Wrang format API data, ' + TEXT.tryLater);
            const newSelectData: SelectItemType[] = [ALL_GROUPS_TYPES];

            resp.origin_sources.forEach((itm) => {
                if (itm !== '') {
                    newSelectData.push({
                        title: ParticipantSources[itm],
                        value: itm,
                        id: itm,
                    });
                }
            });

            setSelectOriginSource(newSelectData);
        } catch (error) {
            setErrorText(`${error}`);
        } finally {
            setIsLoadingOriginSource(false);
        }
    };

    React.useEffect(() => {
        setIsLoadingTeam(true);
        getTeams();
        // eslint-disable-next-line
    }, []);

    const originSourceSelectHandler = (data: SelectItemType) => {
        if (
            data.value === ALL_GROUPS_TYPES.value &&
            selectedOriginSource[0].value === ALL_GROUPS_TYPES.value
        ) {
            return null;
        }
        const newData = deepClone(selectedOriginSource) as SelectItemType[];
        const originSources: SelectItemType[] = [];

        if (data.value === ALL_GROUPS_TYPES.value) {
            setSelectedOriginSource([ALL_GROUPS_TYPES]);
            setSelectedOriginSource([ALL_GROUPS_TYPES])
            return
        }

        let hasVal = false;

        if (newData[0] && newData[0].value === ALL_GROUPS_TYPES.value) {
            newData.splice(0, 1);
        }

        newData.forEach((itm, i) => {
            if (itm.value === data.value) {
                newData.splice(i, 1);
                hasVal = true;
            }
        });

        if (!hasVal) {
            newData.push(data);
        }

        newData.forEach((itm) => {
            if (itm.value !== ALL_GROUPS_TYPES.value) {
                originSources.push(itm);
            }
        });

        if (!newData[0]) {
            newData.push(ALL_GROUPS_TYPES);
        }

        setSelectedOriginSource(newData);
        return
    };


    const clickHandler = (currentStep: number) => {
        switch (currentStep) {
            case 1: {
                setStep(currentStep + 1);
                return;
            }
            case 2: {
                setStep(currentStep + 1);
                return;
            }
        }
    };

    const renderBody = () => {
        // if (permissionError) return <Loader isFixed isGreen />;
        // if (isLoading) return <Loader isFixed isGreen />;
        // if ('') return <Alert text={''} />;
        switch (step) {
            case 1: {
                return (
                <div>
                        <MainBodyWrapper>
                            <FirstSelectGroupWrapper>
                                    <DefaultSelect
                                        isDisabled={false}
                                        data={selectTeams}
                                        onChange={getWorkspaces}
                                        selected={selectedTeam}
                                        label={'Team'}
                                        placeHolder={'Select Team'}
                                        isSearch={true}
                                        isLoading={isLoadingTeam}
                                    />

                                    <DefaultSelect
                                        isDisabled={stepNotPage < 2}
                                        data={selectWorksapces}
                                        onChange={getPackages}
                                        selected={selectedWorkspace}
                                        label={'Workspace'}
                                        placeHolder={'Select Workspace'}
                                        isSearch={true}
                                        isLoading={isLoadingWorkspace && stepNotPage === 2}
                                    />
                            </FirstSelectGroupWrapper>
                        </MainBodyWrapper>
                        <ButtonDefS
                            onClick={() => {
                                clickHandler(step);
                            }}
                            disabled={
                                (step === 1 &&
                                    (!selectedTeam || !selectedWorkspace || !!errorText))
                            }
                            style={{ maxWidth: '97px' }}
                        >
                            Next
                        </ButtonDefS>
                    </div>
                );
            }

            case 2: {
                return (
                    <div>
                        <MainBodyWrapper>
                            <FirstSelectGroupWrapper>
                                    <DefaultSelect
                                        isDisabled={stepNotPage < 3}
                                        data={selectPackage}
                                        icon={AssessmentIcon}
                                        selected={selectedPackage}
                                        label="Choose Package"
                                        onChange={getCycles}
                                        placeHolder="Choose Package"
                                        isLoading={isLoadingPackage && stepNotPage === 3}
                                    />

                                    <DefaultSelect
                                        isDisabled={stepNotPage < 4}
                                        data={selectCycle}
                                        label="Choose Cycle"
                                        placeHolder="Choose Cycle"
                                        icon={AssessmentIcon}
                                        selected={ selectedCycle }
                                        onChange={callOriginSources}
                                        isLoading={isLoadingCycle && stepNotPage === 4}
                                    />

                                    <DefaultSelect
                                        isDisabled={stepNotPage < 5}
                                        data={selectOriginSource}
                                        label="Choose Group Types"
                                        placeHolder="Choose Group Types"
                                        icon={AssessmentIcon}
                                        selected={ selectOriginSource[0]}
                                        onChange={originSourceSelectHandler}
                                        activeSelects={selectedOriginSource?.length !== 0 ? selectedOriginSource : [] }
                                        isMultiple
                                        isLoading={isLoadingOriginSource && stepNotPage === 5}
                                    />

                                {/*<DefaultSelect*/}
                                {/*    data={packages}*/}
                                {/*    onChange={setCurrentPackage}*/}
                                {/*    selected={currentPackage}*/}
                                {/*    label={'Package'}*/}
                                {/*    placeHolder={'Select Package'}*/}
                                {/*    isSearch={true}*/}
                                {/*/>*/}
                                {/*<DefaultSelect*/}
                                {/*    data={cycles}*/}
                                {/*    onChange={setCurrentCycle}*/}
                                {/*    selected={currentCycle}*/}
                                {/*    label={'Cycle'}*/}
                                {/*    placeHolder={'Select Cycle'}*/}
                                {/*/>*/}
                                {/*<DefaultSelect*/}
                                {/*    data={selectOriginSource}*/}
                                {/*    label="Choose Classification"*/}
                                {/*    placeHolder="Choose Classification"*/}
                                {/*    icon={AssessmentIcon}*/}
                                {/*    selected={ selectOriginSource[0]}*/}
                                {/*    onChange={originSourceSelectHandler}*/}
                                {/*    activeSelects={selectedOriginSource?.length !== 0 ? selectedOriginSource : [] }*/}
                                {/*    isMultiple*/}
                                {/*    isLoading={isLoadingOriginSource}*/}
                                {/*/>*/}
                            </FirstSelectGroupWrapper>
                        </MainBodyWrapper>
                        <ButtonDefS
                            onClick={!isLoading ? () => genXlsx() : () => null}
                            style={{ maxWidth: '98px' }}
                            disabled={stepNotPage < 5 && isLoadingOriginSource}
                        >
                            {isLoading ? <Loader size={0.5} /> : 'Export'}
                        </ButtonDefS>
                    </div>
                );
            }
        }
    };

    return (
        <>
            <div style={{ padding: '100px 20px', maxWidth: '900px', margin: 'auto' }}>
                <TitleWrapperS>
                    <h1>Group Report</h1>
                    <ButtonBorderedS
                        style={{ maxWidth: '200px' }}
                        onClick={() => history.push(ROUTE_PATH.admin)}
                    >
                        Go to the main page
                    </ButtonBorderedS>
                </TitleWrapperS>

                {renderBody()}

                {errorText && <Alert text={errorText} />}
            </div>
        </>
    );
};
const TitleWrapperS = styled(PageTitleWrapperS)`
  justify-content: space-between;
  width: 100%;
`;

const MainBodyWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FirstSelectGroupWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

